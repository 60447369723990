import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tooltip,
} from "@chakra-ui/react"
import * as React from "react"
import { CheckIcon, EmailIcon } from "@chakra-ui/icons"
import { FaUser } from "react-icons/fa"
import { Field, Form, Formik } from "formik"

import { NewsletterFormProps, ValidFormValues } from "./forms"
import api from "../utils/api"
import { MarketingContext } from "../components/CoreUI/MarketingContext"
import { convertFormValuesToGqlQuery } from "../components/CoreUI/utils/forms"
import { fireGtmEvent } from "../utils/gtm"
import type CoreUI from "../components/CoreUI/types"

function validateEmail(email?: string): string {
  if (!email) {
    return `Oops — an email address is required.`
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return `Hmm... This doesn't look like a valid email.`
  }
  return ``
}

function validateName(name?: string): string {
  if (!name) {
    return `We'd rather not call you Stranger :)`
  }
  return ``
}

async function submitFormData(
  values: ValidFormValues,
  actions: any,
  eventLabel: string,
  onSubmit?: ({}) => void
) {
  const query = convertFormValuesToGqlQuery(values)
  const { data, status } = await api.post(``, { query })
  actions.setSubmitting(false)
  actions.setStatus(status)
  if (onSubmit) {
    onSubmit(values)
  }
  fireGtmEvent({
    eventName: `form_submission`,
    eventAction: `Form Submission`,
    eventCategory: values.form_name,
    eventLabel,
  })
}

function NewsletterForm({
  automationId = 31,
  buttonColor = `green`,
  buttonId = `newsletter-form`,
  buttonText = `Sign Up`,
  errorHeader = `Error`,
  errorMessage = `Something went wrong.`,
  eventLabel = `Newsletter`,
  fieldWidth = `275px`,
  includeName = false,
  layout = `inline`,
  name = `Newsletter Signup`,
  onSubmit = null,
  size = `md`,
  successHeader = `Success`,
  successMessage = `Thanks for signing up!`,
  syncDeal = false,
}: NewsletterFormProps): JSX.Element {
  const mkt = React.useContext<CoreUI.TMarketingContext>(MarketingContext)
  const utmParams = mkt?.getUtmParamsObject()

  const initialValues: ValidFormValues = {
    automation_id: automationId,
    email: ``,
    name: ``,
    form_name: name,
    sync_deal: syncDeal,
    ...utmParams,
  }

  const direction = layout === `inline` ? `row` : `column`

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        submitFormData(values, actions, eventLabel, onSubmit)
      }}
    >
      {props => {
        if (props.status) {
          const status = props.status === 200 ? `success` : `error`
          const title = props.status === 200 ? successHeader : errorHeader
          const msg = props.status === 200 ? successMessage : errorMessage
          const isLg = size === `lg`
          return (
            <Alert h={isLg ? `60px` : `40px`} rounded="md" status={status}>
              <AlertIcon />
              <AlertTitle mr={2}>{title}</AlertTitle>
              <AlertDescription>{msg}</AlertDescription>
            </Alert>
          )
        }
        return (
          <Form>
            <Stack direction={{ base: `column`, sm: direction }} spacing={2}>
              {includeName && (
                <Field name="name" validate={validateName}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                      isRequired
                    >
                      <InputGroup
                        borderColor="gray.400"
                        size={size}
                        w={fieldWidth}
                      >
                        <InputLeftElement pointerEvents="none">
                          <Icon as={FaUser} color="gray.400" />
                        </InputLeftElement>
                        <Input
                          {...field}
                          bg="white"
                          id="name"
                          placeholder="Your Name"
                          _placeholder={{ color: `gray.400` }}
                        />
                      </InputGroup>
                    </FormControl>
                  )}
                </Field>
              )}
              <Field name="email" validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                    isRequired
                  >
                    <Tooltip
                      hasArrow
                      isOpen={!!form.errors.email && !!form.touched.email}
                      label="Is this a valid email address?"
                      placement="top"
                    >
                      <InputGroup
                        borderColor="gray.400"
                        size={size}
                        w={fieldWidth}
                      >
                        <InputLeftElement pointerEvents="none">
                          <EmailIcon color="gray.400" />
                        </InputLeftElement>
                        <Input
                          {...field}
                          bg="white"
                          id="email"
                          placeholder="Your Email"
                          _placeholder={{ color: `gray.400` }}
                        />
                      </InputGroup>
                    </Tooltip>
                  </FormControl>
                )}
              </Field>
              <Box>
                <Button
                  colorScheme={buttonColor}
                  id={buttonId}
                  isDisabled={
                    (includeName && !Object.keys(props.touched).length) ||
                    !props.isValid ||
                    props.isSubmitting
                  }
                  isLoading={props.isSubmitting}
                  rightIcon={<CheckIcon />}
                  size={size}
                  type="submit"
                  w="100%"
                >
                  {buttonText}
                </Button>
              </Box>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

export default NewsletterForm
